.wrapperLight {
  background-color: rgba(var(--bs-light-rgb), 1) !important;
}
.productItem {
  .title {
    margin-bottom: 0.25rem;
    a {
      font-size: 0.9rem;
      line-height: 1;
      font-weight: 800;
      color: #343f52;
      &:hover,&:active {
        color: var(--bs-link-color)
      }
    }
  }
  .itemHistory {
    bottom: auto;
    top: 1rem;
    background: #fff;
    opacity: 0;
    position: absolute;
    right: 0;
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
    z-index: 1;
    transition: all .3s ease-in-out;
    color: #343f52;
    background: rgba(255,255,255,.7);
    box-shadow: 0 0.25rem 0.75rem rgba(30,34,40,.02);
    font-size: 1rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &:hover {
      color: var(--bs-primary);
      transform: scale(1.2);
    }
  }
  .itemCart {
    opacity: 0;
    position: absolute;
    bottom: -2rem;
    padding: 0.8rem;
    margin: 0;
    left: 0;
    width: 100%;
    height: auto;
    color: #fff;
    background: rgba(38,43,50,.8);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    transition: all .3s ease-in-out;
    font-size: .85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  figure {
    position: relative;
    border-radius: 0.4rem;
    margin-bottom: 1.5rem;
    background: rgb(248, 248, 248);

    .imageWrapper {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      .productImage {
        mix-blend-mode: multiply;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
      }
      .shopLogo {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 25%;
        border-radius: 0;
      }
    }
  }

  &:hover {
    .itemHistory {
      opacity: 1;
      right: 1rem;
    }
    .itemCart {
      opacity: 1;
      bottom: 0;
    }
  }
}
.priceWrapper {
  align-items: end;
  justify-content: space-between;
  display: flex;
  .priceLine {
    display: flex;
    gap: 1em;
    align-items: end;
    .discount {
      margin: 0;
      font-weight: 900;
      line-height: 1.4;
      color: var(--bs-success);
      gap: .25rem;
      display: flex;
      word-spacing: .1rem;
      letter-spacing: -.01rem;
      font-size: 1.2em;
    }
    .base {
      font-weight: 100;
      font-size: 0.9em;
    }
  }
  .old {
    font-weight: 500;
    text-decoration: line-through;
  }
}

.productPage {
  .otherShops {
    li {
      transition: all 0.2s ease;
      &:hover {
        box-shadow: 0 0 1.25rem rgba(30, 34, 40, .1);
      }
    }
  }
}
